@import './../vars/vars';

.representativeTab,
.notaryTab{
	display: flex;

	&_newRepresentativeForm,
	&_newNotaryForm {
		width: 300pt;
		min-width: 300pt;
	}
}

.recordTab {
	display: flex;
	&_newRecordForm {
		width: 100%;
		min-width: 300pt;
	}
}
.headerTableText,
.removeButton {
	padding: 22px 2px 22px 0px;

	&>div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 85%;
		padding-left: 5px;
	}
}

.endDateHeader {
	padding-right: 0;
}

.tableCellRepresentative {
	padding: 12px 0px 12px 5px;
	font-size: 14px;
}

.nameCell {
	font-weight: bold;
}

.deleteCell {
	cursor: pointer;
	font-size: 150%;
	padding-right: 5px;
	text-align: center;
}

.representativeForm,
.notaryForm,
.recordForm,
.socialCapitalForm,
.stockholderForm,
.positionForm {
	&_inputs {
		margin-right: 30px !important;
		padding-right: 20px;

		&_border {
			border-right: 1px solid $borderColor;
		}
	}

	&_buttons {
		float: left;
	}
}

.peoplePickerContainer {
	.ms-PeoplePicker {
		border: 2px solid $grayscale-gray2;
	}
}

.searchPeopleWithDelete {
	display:flex;
	i{
		color: hsl(0, 0%, 80%);
	}
}

.searchPeopleLongWidth {
	width: 140%;
}

.countryLongWidth {
	width: calc(140% - 32px);
}

/*--REPRESENTATIVE DETAIL INFO--*/

.representative_info_row {
	margin-bottom: 6px !important;
}

.col_border {
	width: auto !important;
	padding-right: 36px !important;
	border-right: solid;
	border-width: 0.5pt;
	color: $font-color-light;
}

.col_border:after {
	top: 20%;
	right: 0;
	width: 2px;
	height: 50%;
}

.back_arrow {
	position: absolute;
	left: -17px;
	top: 35px;
	font-family: $fontSymbolsAssets !important;
	font-size: 15px !important;
}

.back_arrow:hover {
	cursor: pointer;
	opacity: 0.5;
}

.back_arrow+h1 {
	margin: -10px 0px 0px !important;
	font-family: $fontLight;

}

.detail_representative_name {
	font-family: $fontLight;
	font-size: 24px;
	color: $font-color-medium;
	padding-bottom: 2px;
}


/*--PERSONAL DATA COLUMNS--*/

.detail_title {
	margin-bottom: -23px !important;
}

.detail_representative_name p {
	margin-top: 0px;
	margin-bottom: -8px !important;
	margin-left: 0px;
	text-transform: capitalize;
}

.personal_data p {
	margin-bottom: -4px !important;
	margin-left: 0px;
}

div .personal_data {
	font-family: $fontLight;
	font-size: 14px;
	color: $font-color-medium;
}

div p.personal_data {
	font-family: $fontBold;
	font-size: 14px;
	color: $font-color-medium;
}

div .society_combo {
	padding-left: 37px !important;
	margin-top: -16px;
}

/*--SOCIEDAD TITLE--*/

.society_combo p {
	font-family: $font;
	font-size: 20px;
	color: $font-color-medium;
	margin-bottom: 9px !important;
}

.combo_element {
	margin-top: 20px !important;
	max-width: 269px;
}

.combo_element :disabled {
	background-color: $grayscale-white;
}

.grid_title_count {
	font-family: $font;
	font-size: 20px;
	color: $font-color-medium;
}
.button-download {
	float: right;
	vertical-align: top !important;
}

/*--TABLE DETAIL--*/

.detailTable {
	display: table !important;
	thead {
		display: table-header-group !important;
		tr{
			display: table-row !important;
			th {
				width: 1% !important;
			}
			th.simple-border-right {
				border-right: 1px solid #ccc
			}
		}
	}
	tbody {
		display: table-row-group !important;
		tr{
			display: table-row !important;
			td {
				width: 1% !important;
			}
			td.align-right {
				text-align: right;
			}
			td.align-center {
				text-align: center;
			}
		}
	}

}