﻿@import './vars/vars';

div.main-container__table {
    table {
        display: block;
        border-collapse: collapse;

        tbody {
            display: block;
            overflow-y: auto;
            max-height: 615px !important;
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0 !important
            }

            tr {
                min-height: 60px;
                border-bottom: 1px solid #ccc;
                display: flex;

                td {
                    width: 100%;
                }

                td.departmentTable {
                    width: 25% !important;
                    min-width: 315px !important;
                }

                td.positionTypeTable {
                    width: 25% !important;
                    min-width: 315px !important;
                }

                td.bodyTypeTable {
                    width: 25% !important;
                    min-width: 315px !important;
                }

                td.deedTable {
                    width: 20% !important;
                }

                td.deleteItem {
                    padding: 11px;
                }
            }
        }

        thead {
            display: block;

            tr {
                display: flex;
                border-bottom: 1px solid #ccc;

                th {
                    width: 100%;
                    font-size: 10px !important;
                }

                th.departmentTable {
                    width: 25%;
                    min-width: 315px !important;
                }

                th.positionTypeTable {
                    width: 25%;
                    min-width: 315px !important;
                }

                th.bodyTypeTable {
                    width: 25%;
                    min-width: 315px !important;
                }

                th.deedTable {
                    width: 20% !important;
                }

                th.authTable {
                    &-colAuth {
                        width: 10% !important;
                    }

                    &-colNif {
                        width: 1% !important;
                    }

                    &-colSoc {
                        width: 10% !important;
                    }

                    &-colAccount {
                        width: 19% !important;
                    }

                    &-colDateCreate {
                        width: 13% !important;
                    }

                    &-colDateRevoc {
                        width: 1% !important;
                    }

                    &-colState {
                        width: 1% !important;
                    }
                }
            }
        }
    }
}

.status-cell {
    .ms-Icon--StatusCircleInner {
        margin-right: 8px;
        font-size: 10px;

        &.status-green {
            color: #32b832;
        }

        &.status-red {
            color: #d80027;
        }
    }

    .ms-Icon--CompletedSolid {
        margin-right: 8px;
        font-size: 10px;

        &.status-green {
            color: #32b832;
        }
    }

    .ms-Icon--StatusErrorFull {
        margin-right: 8px;
        font-size: 10px;

        &.status-red {
            color: #d80027;
        }
    }

    &-rowAuth {
        width: 9.2em !important;
    }

    &-rowNif {
        width: 10.8em !important;
    }

    &-rowSoc {
        width: 8.7em !important;
    }

    &-rowAccount {
        width: 16.7em !important;
    }

    &-rowDateCreate {
        width: 12.7em !important;
    }

    &-rowDateRevoc {
        width: 8.67em !important;
    }

    &-rowState {
        padding-left: 4.0em !important;
    }
}

table.internal-table {
    border-collapse: collapse;
    display: block;

    tbody {
        display: block;
        overflow-y: auto;
        max-height: 500px !important;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            width: 0 !important
        }

        tr {
            justify-content: space-between;
            display: flex;
            align-items: center;
            min-height: 60px;
            border-bottom: 1px solid #ccc;

            td {
                width: 10%;
                font-size: 10px;
            }
        }
    }

    thead {
        display: block;

        tr {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;

            th {
                width: 10%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 10px;
            }
        }
    }
}

th {
    font-family: $fontBold;
    font-size: 11px;
    color: $primary-gris;
    vertical-align: middle;
    text-align: left;
    text-transform: uppercase;
    padding: 22px 11px;
    white-space: nowrap;

    i {
        padding-left: 10px;
    }

    .table-header {
        display: flex;
        align-items: center;

        i {
            display: block;
            color: #a6a6a6;
            padding-left: 15px;

            &.selected {
                color: #ebb700;
            }
        }
    }
}

td {
    font-family: $font;
    font-size: 11px;
    color: $font-color-dark;
    padding: 22px 11px;
    text-align: left;
}

.order-buttons {
    cursor: pointer;
}

.customPaddingRepresentatives {
    padding: initial;
}

.customMarginRepresentatives {
    margin: 22px;
    margin-left: 0px;
    margin-right: 0px;
}

.columnPadding {
    padding-left: 19px;
}

.thBottomPadding {
    padding-bottom: 22px;
}

.mainColumn {
    font-weight: bold;
}

.select-i-table {
    position: relative;
    pointer-events: none;
    cursor: pointer;
    right: 13px;
    float: right;
    top: -25px;
    margin-left: 0 !important;
}

td.clickableCell {
    cursor: pointer;
}

.table-withoutMinWidth {
    thead {
        th {
            min-width: unset;
        }
    }
}

.optionsGrid {
    border: none;
    font-size: 20px;
    padding: 0;

    i {
        display: none;
    }

    :hover {
        background-color: $grayscale-white;
    }
}

.tdNoBorder {
    border: none;

    i {
        display: none;
    }
}

.tdNoBorderAlignRight {
    border: none;
    float: right;

    i {
        display: none;
    }
}

div[class*='ContextualMenu'] {
    width: 7em;
    font-family: $font;
}


/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    tableExp {
        max-height: none;
        display: block;
    }

    /* Force table to not be like tables anymore */
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        border-bottom: 1px solid #bbb;

        &:nth-of-type(odd) {
            background: #eee;
        }
    }

    td {
        /* Behave  like a "row" */
        border: none;
        border-top: 1px solid #bbb;
        position: relative;
        padding: 5px;
        padding-left: 50%;
        color: #747678;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            color: black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:nth-of-type(1) {
            color: black;

            &:before {
                content: 'Sociedad';
            }
        }

        &:nth-of-type(2):before {
            content: 'Tipo';
            display: none;
        }

        &:nth-of-type(3):before {
            content: 'SubTipo';
        }

        &:nth-of-type(4):before {
            content: 'Fecha';
        }
    }

    /*
	Label the data
	*/
}

@media print {

    header,
    button,
    a,
    h1,
    h1+hr {
        display: none;
    }

    table.representatives {
        max-height: none;
        display: block;
        overflow: visible;

        /* Force table to not be like tables anymore */
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 2px solid #bbb;
            margin-bottom: 10px;

            &:nth-of-type(odd) {
                background: #eee;
            }
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-top: 1px solid #bbb;
            position: relative;
            padding: 5px;
            padding-left: 50%;
            color: #747678;
            white-space: nowrap;

            &:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                color: black;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-of-type(1) {
                color: black;
                background-color: lightgray;

                &:before {
                    content: 'Facultad';
                }
            }

            &:nth-of-type(2):before {
                content: 'Tipo de facultad';
            }

            &:nth-of-type(3):before {
                content: 'Notario que otorga';
            }

            &:nth-of-type(4):before {
                content: 'Fecha de otorgamiento';
            }

            &:nth-of-type(5):before {
                content: 'Protocolo de otorgamiento';
            }

            &:nth-of-type(6):before {
                content: 'Inscripción de otorgamiento';
            }

            &:nth-of-type(7):before {
                content: 'Notario que revoca';
            }

            &:nth-of-type(8):before {
                content: 'Fecha de revocación';
            }

            &:nth-of-type(9):before {
                content: 'Protocolo de revocación';
            }

            &:nth-of-type(10):before {
                content: 'Inscripción de revocación';
            }
        }
    }

    /*
	Label the data
	*/
    table.stockHolders {
        max-height: none;
        display: block;
        overflow: visible;

        /* Force table to not be like tables anymore */
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 2px solid #bbb;
            margin-bottom: 10px;

            &:nth-of-type(odd) {
                background: #eee;
            }
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-top: 1px solid #bbb;
            position: relative;
            padding: 5px;
            padding-left: 50%;
            color: #747678;
            white-space: nowrap;

            &:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                color: black;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-of-type(1) {
                color: black;
                background-color: lightgray;

                &:before {
                    content: 'Accionista';
                }
            }

            &:nth-of-type(2):before {
                content: 'Acciones';
            }

            &:nth-of-type(3):before {
                content: 'Numeración de acciones';
            }

            &:nth-of-type(4):before {
                content: '%';
            }
        }
    }

    table.socialCapital {
        max-height: none;
        display: block;
        overflow: visible;

        /* Force table to not be like tables anymore */
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 2px solid #bbb;
            margin-bottom: 10px;

            &:nth-of-type(odd) {
                background: #eee;
            }
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-top: 1px solid #bbb;
            position: relative;
            padding: 5px;
            padding-left: 50%;
            color: #747678;
            white-space: nowrap;

            &:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                color: black;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-of-type(1) {
                color: black;
                background-color: lightgray;

                &:before {
                    content: 'Acciones';
                }
            }

            &:nth-of-type(2):before {
                content: 'Numeración';
            }

            &:nth-of-type(3):before {
                content: 'Nominal';
            }

            &:nth-of-type(4):before {
                content: 'Desembolso';
            }

            &:nth-of-type(5):before {
                content: 'Títulos';
            }
        }
    }

    table.adminBody {
        max-height: none;
        display: block;
        overflow: visible;

        /* Force table to not be like tables anymore */
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 2px solid #bbb;
            margin-bottom: 10px;

            &:nth-of-type(odd) {
                background: #eee;
            }
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-top: 1px solid #bbb;
            position: relative;
            padding: 5px;
            padding-left: 50%;
            color: #747678;
            white-space: nowrap;

            &:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                color: black;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-of-type(1) {
                color: black;
                background-color: lightgray;

                &:before {
                    content: 'Administradores Mancomunados';
                }
            }

            &:nth-of-type(2):before {
                content: 'Cargos';
            }

            &:nth-of-type(3):before {
                content: 'Fecha Nombramiento';
            }

            &:nth-of-type(4):before {
                content: 'Caducidad';
            }
        }
    }

    table.auditors {
        max-height: none;
        display: block;
        overflow: visible;

        /* Force table to not be like tables anymore */
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 2px solid #bbb;
            margin-bottom: 10px;

            &:nth-of-type(odd) {
                background: #eee;
            }
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-top: 1px solid #bbb;
            position: relative;
            padding: 5px;
            padding-left: 50%;
            color: #747678;
            white-space: nowrap;

            &:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                color: black;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-of-type(1) {
                color: black;
                background-color: lightgray;

                &:before {
                    content: 'Auditores';
                }
            }

            &:nth-of-type(2):before {
                content: 'Ejercicio';
            }
        }
    }
}

td.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

td.clickableCell.ellipsis {
    width: 18%;
    max-width: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

td.customRightAligned {
    text-align: right;
}

td.customCenterAligned {
    text-align: center;
}

td.customLeftAligned {
    text-align: left;
}

td.clickableCell.customLeftAligned {
    text-align: left;
}

th.customPaddingRepresentatives.columnPadding.thBottomPadding.subject {
    width: 18%;
    max-width: 40px;
}

td.clickableCell.removeTdSpace {
    width: 10px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
}


/*Hide empty cell for small screen-width*/
@media (max-width: 1400px) {
    div.main-container__table table tbody tr td.removeTSpace { 
         display: none;
    }
  }