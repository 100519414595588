﻿.basicContext {
    padding: 6px 0;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .4), 0 0 1px rgba(0, 0, 0, .2);
    border-radius: 0px;

    &__item {
        margin-bottom: 2px;

        &--separator {
            margin: 4px 0;
            background-color: rgba(0, 0, 0, .1);
        }

        &--disabled {
            opacity: .5;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__data {
        padding: 6px 8px;
        color: #333;
        border-radius: 2px;
    }

    &__item:not(.basicContext__item--disabled):hover &__data {
        color: black;
        background-color: #eee;
    }

    &__item:not(.basicContext__item--disabled):active &__data {
        background-color: darken(#eee, 10%);
    }

    &__icon {
        margin-right: 10px;
        width: 12px;
        text-align: center;
    }
}
