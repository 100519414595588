@import './vars/vars';

/*--PAGINATION SPIN BUTTON--*/

.spin_pagination {
    display: inline-flex;
    justify-content: flex-end;
    padding-top: 33px;
    padding-left: 0px;
    padding-right: 0px !important;
}

.spin_box {
    max-width: 40px;
}


/*--SPIN BUTTON INPUT BOX SIZE--*/

.ms-spinButton-input input {
    border: none !important;
    margin-right: 0px;
}

.pagination_arrow {
    display: inline-flex;
}


/*PAGINATION ARROWS*/

.main-container__content_pagination a {
    padding: unset !important;
}

.pagination_arrow a {
    margin-top: -30px;
    margin-right: 10px;
    min-width: 35px;
    min-height: 35px;
    height: 25px;
    background-color: $primary-main;
}

.pagination_arrow a:hover {
    background-color: $paginationLinkHover !important;
}

.ms-Icon--ChevronRight,
.ms-Icon--ChevronLeft {
    padding-top: 6px;
    padding-left: -25px;
    font-size: 150%;
    color: $grayscale-white;
}

.pagination_text {
    font-family: $font;
    font-size: 13px;
    color: $paginationLabelText;
    margin: 8px 41px 5px 14px;
}

.ms-spinButton-input label {
    font-family: $font;
    font-size: 13px;
    color: $paginationLabelText;
    margin: 8px 4px 10px 14px;
}

.second label {
    margin: 8px 4px 10px 30px;
}


/*PAGINATION NUMBERS INPUT*/

.ms-spinButton-input input[type='text'],
textarea[type='text'] {
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    border-style: none;
    background: $grayscale-white;
    height: 32px;
    position: relative;
    font-family: $fontBold;
    font-size: 14px;
    border-radius: 0;
    color: $font-color-dark;
    padding: 0 12px 0 12px;
    width: 100%;
    text-overflow: ellipsis;
    outline-color: $primary-main;
    cursor: pointer;
    max-width: 62px;
}


/*SOCIETY COMBO INPUT*/

.ms-ComboBox-container>div>input[type='text'],
textarea[type='text'] {
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    border: none;
    background: $grayscale-white;
    height: 32px;
    position: relative;
    font-size: 14px;
    border-radius: 0;
    color: $font-color-dark;
    padding: 0 12px 0 12px;
    width: 100%;
    text-overflow: ellipsis;
}

.ms-spinButton-input label {
    color: $paginationLabelText;
    font-family: $font;
    font-size: 13px;
    font-weight: unset;
}

.msButton-flexContainer {
    background-color: $grayscale-white;
}

button .ms-Button .ms-UpButton {
    background-color: $grayscale-white !important;
}

.ms-spinButton-input>div div:nth-child(2)::after {
    pointer-events: none;
    content: '';
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-color: $userName;
    border-radius: 2px;
}