@import './vars/vars';
.filter {
    position: relative;
    background-color: $grayscale-white;
    padding: 20px 40px 30px 40px;
    &-collapse {
        background-color: $grayscale-white;
        padding-top: 10px;
        position: relative;
    }
    &_header {
        display: flex;
        font-size: 20px;
        font-family: $fontLight;
        padding-left: 14px;
        &-collapse {
            display: flex;
            font-size: 14px;
            font-family: $font;
            padding-left: 14px;
            padding-right: 7px;
            color: $grayscale-gray;
        }
    }
    &_collapse {
        font-size: 16px;
        width: fit-content;
        margin-left: 12px;
        >p {
            background-color: $gray-button-hover;
            padding: 5px 10px;
            margin-right: 15px;
            margin-top: 0;
            height: fit-content;
            float: left;
            .key {
                font-family: $font;
                color: $primary-gris;
                margin-right: 8px;
            }
            .value {
                font-family: $fontBold;
                color: $primary-main;
            }
        }
    }
    &_button {
        background-color: $primary-gris;
        height: 32px;
        padding: 7px 25px;
        text-align: right;
        cursor: pointer;
        &:hover {
            background-color: white;
            color: $primary-main;
            border: 1px solid $primary-main;
            text-decoration: none;
        }
        @media only screen and (max-width: 450px) {
            float: none;
            width: 45%;
            font-size: 0.6em;
        }
        &:focus {
            outline: 0 none;
            outline-color: $primary-main;
        }
        &-apply {
            background-color: $primary-main;
            color: $grayscale-white;
            font-family: $font;
            letter-spacing: 0.08em;
            font-size: 14px;
            border: none;
            height: 32px;
            padding: 8px 25px;
            text-align: right;
            cursor: pointer;
            &:hover {
                background-color: $hoverApplyButton;
                text-decoration: none;
            }
            &:focus {
                outline: 0 none;
                outline-color: $primary-main;
            }
        }
        &-clean {
            background-color: $grayscale-white;
            color: $cleanButtonText;
            font-family: $font;
            letter-spacing: 0.08em;
            font-size: 14px;
            border: none;
            margin-right: 25px;
            height: 32px;
            padding: 8px 17px;
            text-align: right;
            cursor: pointer;
            &:hover {
                background-color: $grayscale-backgorund;
                text-decoration: none;
            }
            &:focus {
                outline: 0 none;
                outline-color: $primary-main;
            }
        }
        &-cancel {
            background-color: $primary-gris;
            float: right;
            margin-top: 15px;
            margin-right: 15px;
            cursor: pointer;
            &:hover {
                background-color: white;
                color: $primary-gris;
                border: 1px solid $primary-gris;
                text-decoration: none;
            }
            &:focus {
                outline: 0 none;
                outline-color: $primary-main;
            }
            @media only screen and (max-width: 450px) {
                float: none;
                width: 45%;
                font-size: 0.6em;
            }
        }
    }
}