﻿@import "./vars/vars";
.main-main__menu {
    background-color: $primary-gris;
    height: 100vh;
    position: fixed;
    padding: 0;
    &_nav {
        ul {
            list-style-type: none;
            padding: 0;
            margin-top: 20px;
            li {
                padding: 10px 0;
                border-left: 3px solid $primary-gris;
                a {
                    color: white;
                    font-size: 0.8em;
                    text-decoration: none;
                    i {
                        font-size: 1.4em;
                        margin-right: 20px;
                        margin-left: 5px;
                        position: relative;
                        top: 4px;
                    }
                }
                &:hover {
                    border-left: 3px solid $primary-main;
                }
            }
        }
    }
    &-mobile {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 60px;
        background-color: $primary-gris;
        padding: 0;
        box-shadow: 0px -3px 10px $primary-gris;
        z-index: 5;
        &_nav {
            text-align: center;
            &-div {
                display: inline-block;
                padding: 10px 0;
                text-align: center;
                width: 19%;
                a {
                    color: white;
                    text-decoration: none;
                    font-size: 0.8em;
                    i {
                        display: block;
                        text-align: center;
                        margin-bottom: 10px;
                        font-size: 1.2em;
                    }
                }
                &:active {
                    background-color: $primary-main;
                }
            }
        }
    }
}