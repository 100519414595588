﻿$primary-main: #ebb700;
$primary-gris: #747678;
$primary-blue: #0f9af7;
$primary-gris-oscuro: #3e3e3e;
$secondary-construccion: #cf4520;
$secondary-aeropuertos: #00313c;
$secondary-servicios: #625d20;
$secondary-comodin: #4b3048;
$grayscale-white: #fff;
$grayscale-gray1: #eee;
$grayscale-gray2: #ddd;
$grayscale-medGray: #bbb;
$grayscale-gray: #aaa;
$grayscale-gray3: #444;
$grayscale-backgorund: #f7f7f7;
$grayscale-library: #ffffff3c;
$gray-button-hover: #f4f4f4;
$hoverApplyButton: #d6a805;
$cleanButtonText: #8d8d8d;
$libaryButtonText: #d8d8d8;
$toggle: #f7b800;
$userName: #d1d1d1;
$btnExcelColor: #747678;
$btnExcelBackgroungColor: #e8e8e8;
$dropZoneBorder: #c7c7c7;
$dropZoneText: #a6a6a6;
$paginationLabelText: #767678;
$paginationLinkHover: #dddede;
$totalRow: #f0f0f0;
$greenTick: #32B832;

$font: 'FerrovialN Regular';
$fontBold: 'FerrovialN Bold';
$fontLight: 'FerrovialN Light';
$white: #ffffff;
$font-color-dark: #333333;
$font-color-medium: #707070;
$font-color-light: #e0e0e0;

$fontAlternative: 'Tahoma';

$fontSymbolsAssets: 'Segoe MDL2 Assets Regular';

$fontAlternative: 'Tahoma';

$borderColor: #D1D1D1;


@font-face {
    font-family: 'FerrovialN Bold';
    src: url(../../assets/fonts/FerrovialN-Bold.ttf);
}

@font-face {
    font-family: 'FerrovialN Regular';
    src: url(../../assets/fonts/FerrovialN-Regular.ttf);
}

@font-face {
    font-family: 'FerrovialN Light';
    src: url(../../assets/fonts/FerrovialN-Light.ttf);
}

@font-face {
    font-family: 'Segoe MDL2 Assets Regular';
    src: url(../../assets/fonts/SegMDL2.ttf);
}