@import './vars/vars';

.spinnerOverlay {
	position: fixed;
	z-index: 40001;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);

	.spinner {
		position: fixed;
		z-index: 40002;
		width: 150px;
		height: 100px;
		top: 50%;
		left: 50%;
		background: url('../../assets/images/spinner-ferro.gif') no-repeat center transparent;
		background-size: 50%;
		background-position-y: 80%;
		text-align: center;
		padding: 10px;
		border-radius: 0;
		border: none;
		margin-left: -50px;
		margin-top: -50px;

		span {
			position: relative;
			top: -10px;
			color: #ebb700;
			font-family: $font;
		}
	}
}

.gridSpinnerOverlay {
	position: absolute;
	z-index: 40001;
	left: 0px;
	width: 100%;
	height: 100%;
	min-height: 50vh;
	background-color: rgba(0, 0, 0, 0.5);

	@media only screen and (max-width: 1024px) {
		height: 80vh;
	}

	.gridSpinner {
		position: absolute;
		z-index: 40002;
		width: 200px;
		height: 100px;
		top: 50%;
		left: 45%;
		background: url('../../assets/images/spinner-ferro.gif') no-repeat center transparent;
		background-size: 50%;
		background-position-y: 80%;
		text-align: center;
		padding: 10px;
		border-radius: 0;
		border: none;
		margin-left: -50px;
		margin-top: -50px;

		span {
			position: relative;
			top: -10px;
			color: #ebb700;
		}
	}
}