﻿.basicContext {
    position: absolute;
    opacity: 0;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
    // Item -------------------------------------------------------------- //
    &__item {
        cursor: pointer;

        &--separator {
            float: left;
            width: 100%;
            height: 1px;
            cursor: default;
        }

        &--disabled {
            cursor: default;
        }
    }

    &__data {
        min-width: 140px;
        padding-right: 20px;
        text-align: left;
        white-space: nowrap;
        border: none;
    }

    &__icon {
        display: inline-block;
    }
    // Scrollable -------------------------------------------------------- //
    &--scrollable {
        height: 100%;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
    }

    &--scrollable &__data {
        min-width: 160px;
    }
}
