@import '../vars/vars';
.new_deed {
    margin-left: -16px !important;
}

.new_deed_form {
    margin-top: 50px !important;
    margin-left: 0 !important;
    background-color: $grayscale-white;
    border: 1px solid $userName;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #ebb700;
    margin-top: 5px;
    border: 1px solid #ebb700;
    color: white;
    width: 90%;
}

.subtitle {
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px;
    color: #747678;
}

.positionTab,
.stockholderTab {
	display: flex;

	&_newPositionForm,
    &_newStockholderForm {
		width: 300pt;
		min-width: 300pt;
	}
}
