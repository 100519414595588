﻿@import './vars/vars';

// @mixin button-div {
//     width: 0.5em;
//     height: 0.5em;
//     position: absolute;
//     top: 0.18em;
//     background-color: rgb(255, 255, 255);
//     box-sizing: border-box;
//     left: 1.4em;
//     border-radius: 0.5em;
//     transition: all 0.1s ease;
//     border-color: transparent;
//     border-width: 0.28em;
//     border-style: solid;
// }

// .toggle-button {
//     position: relative;
//     font-size: 20px;
//     line-height: 1em;
//     box-sizing: border-box;
//     width: 2.2em;
//     height: 1em;
//     cursor: pointer;
//     outline: transparent;
//     border-radius: 1em;
//     transition: all 0.1s ease;
//     border-width: 1px;
//     border-style: solid;
//     background: rgb(0, 120, 215);
//     border-color: transparent;

//     &-label {
//         padding-top: 0px;
//         padding-right: 0px;
//         padding-bottom: 0px;
//         padding-left: 0px;
//         margin-top: 0px;
//         margin-right: 10px;
//         margin-bottom: 0px;
//         margin-left: 10px;
//         user-select: none;
//     }

//     &-div {
//         @include button-div;

//         &-off {
//             @include button-div;
//             background-color: rgb(33, 33, 33);
//             left: 0.2em;
//         }
//     }

//     &-off {
//         @extend .toggle-button;
//         background: rgb(255, 255, 255);
//         border-color: rgb(166, 166, 166);
//     }
// }

.custom-toggle {
	background-color: #fee076;
	border-radius: 40px;
	width: 180px;
	height: 32px;
	text-align: center;
	transition: all 0.4s;
	color: #8d8d8d;
	font-size: 14px;
	font-family: $font;
	label {
		top: 50%;
		transform: translateY(-50%);
		text-transform: uppercase;
	}

	.toggle {
		background-color: #ebb700;
		border-radius: 40px;
	}

	input[type='radio']:checked + label {
		color: #ffffff;
	}
}
