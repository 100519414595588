@import './../vars/vars';

.societyNameFile {
    font-family: $fontLight;
    font-size: 26px;
    color: $primary-gris;
}

.detailsDataTitle {
    font-family: $fontBold;
    font-size: 20px;
    color: $primary-gris;
    margin-top: 14px;
    margin-bottom: 10px;
}

.personal_data {
    font-size: 14px;
    color: $primary-gris;
    margin-bottom: 6px;

    .key {
        font-family: $font;
        text-transform: uppercase;
    }

    .value {
        font-family: $fontBold;
    }

    .text {
        font-family: $font;
    }
}

.separator_line {
    border-right: 1px solid #c7c7c7;
    margin-right: 30px;
}

.subtable_record {
    display: flex;
    align-items: center;
    margin-top: 30px !important;

    .title_subtable_record {
        font-family: $fontLight;
        font-size: 23px;
        color: $font-color-dark;
    }
}

.totalRow {
    background-color: $totalRow;
}