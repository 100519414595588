﻿@import './vars/vars';
.input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    border: 2px solid #ddd;
    background: #ffffff;
    height: 32px;
    position: relative;
    font-size: 14px;
    border-radius: 0;
    color: #333333;
    padding: 0 12px 0 12px;
    width: 100%;
    text-overflow: ellipsis;
    outline: 0;
    font-family: $font;
}

input::placeholder {
    font-family: $font !important;
}

input[type='text']:focus,
textarea[type='text']:focus {
    border-color: $primary-main;
    outline: 0 none;
    outline-color: $primary-main;
}

input[type='text'] {
    border-color: $primary-main;
    outline: 0 none;
}

input[type='text'],
textarea[type='text']:hover {
    cursor: text;
}

textarea{
    min-height: 80px !important;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .main-main__content_table-inline-block.ms-hiddenMdDown .ms-DatePicker {
        margin-bottom: -3.8px;
    }
}