﻿@import './vars/vars';

.select::-ms-expand {
	/* for IE 11 */
	display: none;
}

.select {
	-webkit-appearance: none;
	margin: 0;
	border: 2px solid #ddd;
	background: white;
	cursor: pointer;
	height: 32px;
	position: relative;
	font-size: 14px;
	border-radius: 0;
	-webkit-border-radius: 0px;
	color: #333333;
	padding: 0 12px 0 12px;
	width: 100%;
	text-overflow: ellipsis;
	outline: 0;
	font-family: $font;

	&-i {
		position: relative;
		pointer-events: none;
		cursor: pointer;
		left: -25px;
		top: -25px;
		margin-left: 0 !important;
	}
}
