    @import './vars/vars';
    // contextBasic //
    @import './vendors/basicContext/container';
    @import './vendors/basicContext/context';
    @import './vendors/basicContext/themes/default';

    body {
        background-color: $grayscale-backgorund;
        margin: 0;
    }

    div[class*='toast'] {
        font-family: $font;
    }

    .main {
        height: 100%;
        font-family: $font;
        letter-spacing: 0.6px;
        font-size: 16px;

        &-header {
            display: inline-block;
            width: 100%;
            height: 56px;
            font-family: $font;

            &__logo {
                display: inline-block;
                background-color: $primary-main;
                height: 56px;
                color: $gray-button-hover;
                font-size: 13px;
                padding-top: 5px;
                padding-left: 30px;
                text-decoration: none;

                .image_logo {
                    display: table-cell;
                    height: 29px;
                }

                .title_logo {
                    display: table-cell;
                    margin: 0;
                }
            }

            &__bar {
                display: inline-block;
                background-color: $primary-gris-oscuro;
                height: 56px;

                .div_library {
                    height: 56px;
                    display: inline-block;

                    >a {
                        height: 56px;
                        display: table;
                        position: absolute;
                        right: 3%;
                        top: 9px;
                        text-align: right;
                    }

                    @media only screen and (max-width: 450px) {
                        margin-left: 3.125em;
                    }
                }

                .div_user {
                    height: 56px;
                    display: inline-block;
                    color: $userName;
                    font-size: 14px;
                    position: absolute;
                    padding: 1.3em;

                    >i {
                        padding-top: 18px;
                        width: 26px;
                        height: 40px;
                        min-width: 26px;
                        min-height: 40px;
                        text-align: center;
                        vertical-align: middle;
                        font-size: 18px;
                    }

                    >span {
                        @media only screen and (max-width: 450px) {
                            display: none;
                        }
                    }

                    .ms-Icon--Contact {
                        margin-right: 7px;
                    }

                    .ms-Icon--SignOut {
                        margin-left: 7px;
                        cursor: pointer;
                    }
                }
            }
        }

        &-deedRecordTypes {
            &_button {
                background-color: $primary-main;
                float: right;
                margin-top: 15px;
                padding: 15px 18px;
                border: 1px solid $primary-main;

                &:hover {
                    background-color: white;
                    color: $primary-main;
                    text-decoration: none;
                }
            }
        }

        &-container {
            display: flex;
            height: calc(100% - 56px);
            padding-bottom: 30px;

            h1 {
                font-size: 24px;
                color: $font-color-dark;
                margin-top: 30px;
            }

            &__pageTitle {
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                >span {
                    font-size: 24px;
                    color: $font-color-dark;
                    font-family: $font;
                }
            }

            &__block {
                background-color: $white;
                padding: 28px 40px 14px 40px;
                margin-top: 32px;

                h1.main-container__block-title {
                    margin-top: 0px;
                    font-weight: bold;
                }

                &-section {
                    display: block;

                    &-content {
                        display: flex;
                        flex-wrap: wrap;

                        &-tile {
                            height: 128px;
                            width: 180px;
                            display: flex;
                            background: $gray-button-hover;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                            margin-right: 20px;
                            margin-bottom: 20px;
                            text-decoration: none;
                            border: 1px solid transparent;
                            transition: all 0.3s;
                            font-size: 16px;
                            font-family: $font;
                            color: $font-color-dark;

                            &-icon {
                                border-bottom: 2px solid $primary-main;
                                padding-bottom: 10px;
                                margin-bottom: 10px;
                                transition: all 0.3s;
                                height: calc(55% - 20px);
                                display: flex;
                                align-items: flex-end;
                                justify-content: center;
                                width: 30px;
                                position: relative;

                                >i {
                                    position: absolute;
                                    bottom: 10px;
                                    transition: all 0.3s;
                                    align-items: flex-end;
                                }
                            }

                            &-text {
                                height: 45%;
                                width: 86%;
                                text-align: center;
                                overflow-x: hidden;
                            }

                            &:hover {
                                background-color: $white;
                                border-color: $primary-main;

                                .main-container__block-section-content-tile-icon {
                                    >i {
                                        bottom: 13px;
                                    }
                                }
                            }
                        }
                    }
                }

                &-title {
                    margin-bottom: 24px;
                    font-weight: normal;
                }

                &-subtitle {
                    font-size: 14px;
                    color: $font-color-medium;
                    margin-top: 0px;
                    font-weight: normal;
                }
            }

            hr {
                background-color: $grayscale-gray1;
                height: 2px;
                border: none;
                position: relative;
                top: 2px;
            }

            &-hr_title {
                margin-top: -17px;
                color: $grayscale-gray3;
                margin-bottom: 30px;
            }

            &-hr_before-table {
                margin-top: 20px;
            }

            &__table {
                background-color: $grayscale-white;
                position: relative;
            }

            &__no_results {
                margin-top: 60px;
                background-color: #fff;
                position: relative;
                height: 450px !important;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                align-items: center;
                justify-content: center;

                span {
                    display: block;
                    width: 100%;

                    &:first-of-type {
                        font-size: 24px;
                    }

                    &:last-child {
                        font-size: 20px;
                        color: $grayscale-gray;
                        margin-bottom: auto;
                    }
                }

                img {
                    margin-top: 60px;
                }
            }

            &__content {
                position: relative;

                @media only screen and (max-width: 450px) {
                    margin-bottom: 60px;
                    margin-left: 0;
                }

                @media only screen and (max-width: 1024px) and (min-width: 481px) {
                    height: 100vh;
                    overflow: scroll;
                }

                hr {
                    background-color: $grayscale-gray1;
                    height: 2px;
                    border: none;
                    position: relative;
                    top: 2px;
                }

                &_colButtons {
                    text-align: right;
                    margin-top: 25px;
                }

                &-hr_before-table {
                    margin-top: 20px;
                }

                &-hr_title {
                    margin-top: -10px;
                    color: #444;
                    //margin-bottom: 40px;
                }

                &_title {
                    font-family: $fontBold;
                    padding-left: 20px;

                    h1 {
                        color: $primary-gris;
                    }
                }

                &_pagination {
                    text-align: center;
                    padding: 30px 0 20px 0;
                    background-color: $grayscale-backgorund;

                    @media only screen and (max-width: 450px) {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                    }

                    a {
                        padding: 5px 15px;
                        font-size: 0.9em;
                        text-decoration: none;
                        color: $primary-gris;

                        &:hover {
                            background-color: $grayscale-gray1;
                            color: $primary-gris;
                        }
                    }

                    .active-page {
                        background-color: $primary-main;
                        color: white;
                    }
                }

                &_edit {
                    color: $primary-gris;
                    font-weight: normal;
                    font-size: 13px;

                    strong {
                        color: black;
                    }
                }

                &-context-menu {
                    position: relative;
                    top: 35px;
                    left: -85px;
                    background: white;
                    display: block;
                    height: 90px;
                    width: 110px;
                    padding-top: 10px;
                    border: 1px solid #ccc;
                    box-shadow: 0px 5px 10px $primary-gris;

                    &_a {
                        display: block;
                        color: #747678 !important;
                        padding: 5px 10px;

                        &:hover {
                            background-color: $grayscale-gray1;
                            text-decoration: none;
                        }

                        &>i {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .content_edit {
        &-linkToBack {
            text-decoration: none;
            color: $primary-blue;
            vertical-align: middle;
        }

        &-hide {
            display: none;
        }

        &-show {
            display: inline-block;
        }

        &-editar {
            color: $primary-gris;
            font-weight: normal;

            strong {
                color: black;
            }
        }

        h1 {
            color: $primary-gris;
            font-weight: normal;
        }

        h3 {
            margin: 0;
            position: relative;
            top: 10px;
            margin-left: 10px;
            background-color: white;
            display: inline;
            padding: 0 10px;
            font-weight: normal;
            font-size: 1.17em;
        }

        &_block {
            font-size: 13px;
            color: $primary-gris;
            padding-bottom: 15px;
            padding-left: 15px !important;
            padding-right: 15px !important;

            p {
                margin-bottom: 5px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                @media (max-width: 450px) {
                    width: 100%;
                }
            }

            &-inner {
                margin: 0 5px;
                margin-left: 0;

                &-select {
                    width: 94% !important;
                }

                &-selectOnly {
                    width: 100% !important;
                }

                &-input {
                    width: 55% !important;
                }

                i {
                    font-size: 0.9em;
                    margin-left: 10px;
                    position: relative;
                    top: 2px;
                }

                i.selectOnly-i {
                    top: -22px;
                    float: right;
                    left: -12px;
                }

                p {
                    display: inline;
                    margin-left: 10px;
                }
            }

            &-addButton {
                float: right;
                color: #0f9af7;
                font-size: 1.5em;
                text-decoration: none;
                position: relative;
                top: 2px;
                left: -1%;
                cursor: pointer;

                i {
                    margin-right: 5px;
                }
            }

            &-hr {
                width: 88%;
                margin-left: 0;
            }

            &-toggle {
                label {
                    margin-bottom: 10px;
                    font-size: 0.8em !important;
                    font-weight: normal;
                    color: $primary-gris !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                    @media (max-width: 450px) {
                        width: 100%;
                    }
                }
            }

            &-pBold {
                font-weight: bold;
                color: black;

                +a {
                    left: 0px;
                    top: -14px;
                }
            }

            &-ul {
                padding-left: 10px;
                margin-top: 20px;

                >li {
                    list-style-type: none;
                    height: 25px;

                    >span {
                        font-size: 0.9em;
                        color: $grayscale-medGray;
                    }

                    >i {
                        float: right;
                        position: relative;
                        top: -12px;
                    }
                }
            }

            &-2col {
                padding-right: 25px;
            }

            &-radio-button {
                input[type='radio'] {
                    margin: 0 5px 0 0;
                }

                input+label {
                    margin-right: 20px;
                }
            }

            &-border {
                border: 2px solid $grayscale-gray2;
                padding: 10px 20px;
                width: 80%;

                button {
                    margin-bottom: 20px;
                }
            }

            &-fecha {
                width: 20%;
            }

            &-tabs {
                border: 2px solid $grayscale-gray2;
                padding: 10px;
                font-family: $font;
                font-size: 16px;
                margin-top: 50px;
                background-color: white;

                li.react-tabs__tab--selected {
                    border: none;
                    border-bottom: 4px solid $primary-main !important;
                    font-family: $fontBold;
                }

                &-i {
                    font-size: 2em;
                    cursor: pointer;
                }

                &-row {
                    background-color: $grayscale-gray1;
                    border: 1px solid $grayscale-gray1;
                }
            }

            &_filter {
                font-size: 13px;
                color: $primary-gris;
                padding-bottom: 15px;
                padding-left: 15px !important;
                padding-right: 15px !important
            }
        }
    }

    .delete-files-icon {
        cursor: pointer;
        font-size: 20px;
        margin-left: 5px;
        margin-top: 2px;
        color: red;
    }

    .ms-TextField-fieldGroup {
        min-height: 0px !important;
    }

    /*IMPORTANT!!!!*/

    .ms-Grid-row {
        height: auto !important;
    }

    .td-Delete-button {
        text-align: center;
    }

    select {
        padding: 0 30px 0 10px !important;
        -webkit-padding-end: 30px !important;
        -webkit-padding-start: 10px !important;
    }

    /* Category New */

    .categoryNew {
        &-button {
            margin-bottom: 10px;
        }

        &-select-i {
            left: 95%;
        }

        &-p {
            margin-left: 20px;
        }

        &-label {
            margin-right: 5px;
            vertical-align: top;
        }

        &-hr {
            width: 100%;
        }

        &-img {
            max-width: 100%;
            max-height: 400px;
            margin-top: 20px;
        }
    }

    /*DATE PICKER FILTER*/

    .ms-DatePicker {
        display: inline-block;
        width: 100%;

        div {
            div {
                div {
                    div {
                        border: none !important;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    /*INPUT GENERICO*/

    input,
    textarea {
        &[type='text'] {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-box-shadow: none;
            box-shadow: none;
            margin: 0;
            border: 2px solid #ddd;
            background: #ffffff;
            height: 32px;
            position: relative;
            font-size: 14px;
            border-radius: 0;
            color: #333333;
            padding: 0 12px 0 12px;
            width: 100%;
            text-overflow: ellipsis;
            outline: 0;
            cursor: text;

            &:focus {
                outline: 0 none;
                border-color: $primary-main;
            }
        }
    }

    /*TEXT FIELD*/

    .text__field {
        &-div_block {
            display: inline-block;
        }

        &-p_edit-page {
            display: inline;
            margin-left: 10px;
            top: -27px;
            left: 55%;
            position: relative;
        }
    }

    .ms-TextField-fieldGroup,
    .ms-BasePicker-text {
        border: none !important;
    }

    /*LANGUAGE COMPONENT*/

    .language__component {
        &-icon {
            cursor: pointer;
        }

        &-hide {
            display: none;
        }

        &-show {
            display: inline-block;
        }
    }

    /*INPUT SELECTOR*/

    .input-selector__edit-app {
        width: 88%;
    }

    /*CONTEXT MENU*/

    #ContextualMenu {
        background-color: white;

        div {
            i {
                display: none;
            }

            span {
                color: $primary-gris;
            }
        }
    }

    .context-menu__icon {
        margin-left: 10%;
        color: $primary-main;
    }

    /*ADMINUSER ADD*/

    .main-main__content_edit_p-AdminUser {
        margin-bottom: 10px;
        width: auto;
    }

    .ms-DatePicker-table th {
        min-width: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    button {
        font-family: $font;
    }

    .ms-DatePicker-nextMonth i,
    .ms-DatePicker-prevMonth i,
    .ms-DatePicker-prevYear i,
    .ms-DatePicker-nextYear i {
        transform: rotate(180deg);
    }

    .ms-ComboBox-container>.ms-ComboBox {
        padding-left: 0;

        >input {
            outline: none !important;

            :focus {
                outline: none !important;
            }

            >span:hover {
                cursor: pointer;
            }
        }
    }

    .ms-ComboBox-container>.ms-ComboBox::after {
        border: 2px solid #ddd !important;
    }

    .ms-Toggle-innerContainer,
    .ms-ComboBox-container,
    .ms-ComboBox,
    .ms-ComboBox-optionsContainer,
    .ms-TextField,
    .ms-TextField-wrapper,
    .ms-TextField-fieldGroup,
    .ms-TextField-field {
        font-family: $fontLight !important;
    }

    #SearchBox1 {
        width: 100%;
    }

    .inline-option {
        display: inline-block;
    }

    .flex-option {
        display: flex;
    }

    .is-checked {
        .ms-Toggle-background {
            background-color: $toggle;
        }
    }

    .dropzone {
        display: inline-block;
        text-align: center;
        height: 135px !important;
        border: 1px dashed $dropZoneBorder !important;
        background-color: $grayscale-white !important;
        cursor: pointer;

        >i {
            font-size: 33px;
            color: $userName;
            margin-top: 20px;
        }

        .dropzone-title {
            color: $dropZoneText;
            font-family: $fontBold;
            font-size: 14px;
        }

        .dropzone-maxsize {
            color: $dropZoneText;
            font-family: $font;
            font-size: 10px;
            margin-top: 35px;
        }
    }

    .range-picker {
        display: flex;
        justify-content: space-between;

        .ms-DatePicker {
            width: 49%;
        }
    }

    .search-person {
        div[class*='control'] {
            min-height: 32px;
            max-height: 32px;
            box-shadow: none;
            border-color: hsl(0, 0%, 80%);
            border-left: 0;
            border-radius: 0 4px 4px 0;
            align-content: center;
            cursor: text;

            :hover {
                outline: none;
                border-color: hsl(0, 0%, 80%);
            }
        }

        div[class*='control']>div:nth-of-type(2) {
            display: none;
        }

        div[class*='MenuList'] {
            div[class*='option'] {
                background-color: transparent;
                color: #333333;
            }

            div[class*='option']:active {
                color: #ffffff;
                background-color: #ebb700 !important;
            }

            div[class*='option']:hover {
                color: #ffffff;
                background-color: #ebb700 !important;
            }
        }
    }

    .right_line {
        border-right: 1px solid #c7c7c7;
    }

    .search-select {
        display: flex;
        align-items: center;
        width: 100%;

        .search-icon {
            font-size: 16px;
            padding: 7px 0 7px 7px;
            border: 1px solid hsl(0, 0%, 80%);
            border-radius: 4px 0 0 4px;
            border-right: 0;
            color: #ebb700;
        }

        .search-person {
            width: 100%;
        }
    }

    .isDisabled {
        color: currentColor;
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
        pointer-events: none;
    }

    .unauthorized {
        background-color: #1e92b6;
        color: white;
        width: fit-content;
        padding: 20px 25px;
        margin: 12% auto 0 auto;
        border-radius: 2px;
        display: flex;

        .icon-info {
            font-size: 30px;
        }

        span {
            margin: auto;
            padding-left: 12px;
            font-size: 18px;
        }
    }

    .unauthenticated {
        background-color: #d3d2ce;
        color: rgb(0, 0, 0);
        width: fit-content;
        padding: 20px 25px;
        margin: 12% auto 0 auto;
        border-radius: 2px;
        display: flex;

        .icon-info {
            font-size: 30px;
        }

        span {
            margin: auto;
            padding-left: 12px;
            font-size: 18px;
        }
    }

    .info_results {
        display: flex;
        font-family: $font;
        font-size: 20px;
        color: $font-color-medium;
        padding-top: 60px;
        align-items: center;
    }

    .full-width {
        width: 100% !important;
    }

    .item-rows {
        font-size: 20px;
    }

    div[class*='title'],
    p[class*='subText'],
    span {
        font-family: $font !important;
    }

    div.is-active [class*='fieldGroup']::after {
        border: 1px solid $primary-main !important;
    }

    div.ms-Dialog-main {
        box-shadow: none;
        border: 2px solid $primary-main !important;
    }

    .dialogBtn-delete {
        border: 1px solid $primary-main !important;
    }

    .errors-container {
        margin-top: 16px;
    }

    .tooltipIcon {
        color: $primary-main;
        padding-right: 5px;
        font-size: 14px;
    }

    .tooltipText {
        font-family: $font;
        font-size: 14px;
    }

    @media only screen and (min-width: 1024px) {
        .div_library {
            width: 65%;
        }
    }

    div.ms-Overlay.ms-Overlay--dark {
        background-color: rgba(0, 0, 0, 0.05) !important;
    }


    .ul-documents {
        padding-left: 30px;
        text-align: left;
    }

    .fixMsgMaxError {
        margin-left: -30px;
        width: 253px;
    }

    .datepickerContainer {
        .datePickerLine {
            display: inline-block;
        }

        > div {
            width: 90%;
        }

        button {
            width: 10%;
            background-color: transparent;
        }

        i{
            color: hsl(0, 0%, 80%);
        }
    }    

    .body-type {
        margin-right: 12px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        max-height: 32px;
        span{
            margin-right: 12px;
            font-size: 18px;
        }
        .ms-layer{
            display: none;
        }
        .ms-ComboBox-container  {
            width: 60%;
        }
    }

    .url-style{
        cursor: pointer;
        color: blue;
        text-decoration: underline;
    }