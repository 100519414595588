.tableHeaderCS {
	display: table;
	width: 90%;
}

.tableBodyCS {
	display: table;
	width: 100%;
}

.rowCS {
	display: table-row;
}

.headerCS {
	display: table-header-group;
	font-weight: bold;
}

.cellTitleCS {
	display: table-cell;
	width: 40%; //padding: 5px; border: 1px solid black;
}

.cellFullWidthCS {
	display: table-cell;
	width: 100%; //padding: 5px; border: 1px solid black;
}

.cellCS {
	display: table-cell;
	width: 25%; //padding: 5px; border: 1px solid black;
	margin-left: 5px;
}

.cellHeaderTitleCS {
	display: table-cell;
	width: 45%;
	padding: 5px; //border: 1px solid black;
	padding-left: 10px;
}
.cellHeaderCS {
	display: table-cell;
	width: 25%;
	padding: 5px; //border: 1px solid black;
}

// Contenedor principal
.react-select-container {
	min-height: 32px !important;
}

.react-select-container-disabled {
	min-height: 32px !important;
	background-color: hsl(0,0%,95%);
	color: hsl(0,0%,60%) !important;
	.react-select__control {
		background-color: inherit !important;
	}
	.react-select__single-value{
		color: hsl(0,0%,60%) !important;
	}
}

// Segundo contenedor principal
.react-select__control {
	border-radius: 0px !important;
	border-width: 2px !important;
	min-height: 32px !important;
	border-color: rgb(221, 221, 221) !important;
	box-shadow: none !important;
}

// Contenedor de valores seleccionados
.react-select__value-container {
	padding: 0px 8px 0px 8px !important;
}

.css-b8ldur-Input {
	margin: 0px !important;
}

.react-select__input input {
	height: 18px !important;
}

.react-select__indicator {
	padding: 4px !important;
}

.companyToggle{
	padding: 10px;
}