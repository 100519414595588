@import './vars/vars';

.ms-DatePicker {
	font-family: $font;
	.ms-DatePicker-dayPicker {
		.ms-DatePicker-navContainer {
			margin-right: 12px;
		}

		table {
			margin-right: 12px;

			td[class*='ms-DatePicker-day--highlighted'] {
				background-color: #ebb700 !important;
				color: #ffffff;
			}

			th {
				border: none;
				text-align: center;
			}

			td {
				border: none;
				text-align: center;
				button[class*='dayIsToday'] {
					background-color: rgba(235, 183, 0, 0.6) !important;
				}
			}
		}
	}

	div[class*='divider'] {
		border-right: 1px solid #edebe9 !important;
	}

	.ms-DatePicker-goToday {
		right: 13px;
	}

	.ms-DatePicker-monthPicker {
		margin-left: 12px;

		.ms-DatePicker-currentYear {
			margin-left: 5px;
		}
	}
}
