﻿@import './vars/vars';

.button {
    border-radius: none;
    border: none;
    padding: 15px 24px;
    color: white;
    font-size: 14px;
    text-align: center;
    font-family: $font;
    background-color: $primary-main;
    float: right;
    margin-top: 15px;
    border: 1px solid $primary-main;
    cursor: pointer;

    &:hover {
        background-color: white;
        color: $primary-main;
        text-decoration: none;
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 450px) {
        float: none;
        width: 45%;
        font-size: 0.6em;
    }

    &-primary {
        border-radius: none;
        border: none;
        padding: 15px 24px;
        color: white;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        font-family: $font;
        background-color: $primary-main;
        float: right;
        margin-top: 15px;
        margin-right: 15px;
        border: none;
        text-transform: none;
        cursor: pointer;

        &:hover {
            background-color: $hoverApplyButton;
            color: $primary-gris;
            border: none;
            text-decoration: none;
        }

        &:focus {
            outline: none;
        }

        @media only screen and (max-width: 450px) {
            float: none;
            width: 45%;
            font-size: 0.6em;
        }
    }

    &-cancel {
        border-radius: none;
        border: none;
        padding: 15px 24px;
        color: white;
        font-size: 14px;
        text-align: center;
        font-family: $font;
        background-color: $grayscale-backgorund;
        color: $primary-gris;
        float: right;
        margin-top: 15px;
        margin-right: 15px;
        border: none;
        text-transform: none;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: $primary-gris;
            border: none;
            text-decoration: none;
        }

        &:focus {
            outline: none;
        }

        @media only screen and (max-width: 450px) {
            float: none;
            width: 45%;
            font-size: 0.6em;
        }
    }

    &-delete {
        background-color: $secondary-construccion;
        color: white;
        font-family: $font;
        letter-spacing: 1px;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: $secondary-construccion;
            border: 1px solid $secondary-construccion;
            text-decoration: none;
        }

        .ms-Button-label {
            font-weight: 100;
        }
    }

    &-addButton {
        height: 34px;
        border: 1px solid $primary-main;
        background-color: white;
        font-family: $fontBold;
        font-size: 14px;
        padding: 0px 12px;
        color: $primary-main;
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        text-transform: uppercase;
        cursor: pointer;

        >i {
            padding-right: 8px;
        }

        &:hover {
            background-color: $gray-button-hover;
        }

        @media only screen and (max-width: 450px) {
            >span {
                display: none;
            }

            >i {
                padding: 0;
            }
        }
    }

    &-download {
        background-color: $btnExcelBackgroungColor;
        color: $primary-gris;
        border: none;
        float: right;
        padding: 7px 10px;
        margin: 7px 0 7px 0;
        cursor: pointer;

        i {
            font-size: 15px;
            margin-right: 5px;
        }

        span {
            font-size: 14px;
            font-family: $fontBold;
        }

        &:hover {
            background-color: $grayscale-gray1;
            color: $primary-gris;
        }
    }

    &-library {
        text-align: center;
        vertical-align: middle;
        background-color: $grayscale-library;
        font-family: $fontBold;
        color: $libaryButtonText;
        font-size: 13px;
        width: 120px;
        height: 38px;
        border: none;
        cursor: pointer;

        >i {
            margin-right: 9px;
            font-size: 18px;
            vertical-align: middle;
        }

        >span {
            vertical-align: middle;
        }

        @media only screen and (max-width: 450px) {
            font-size: 0.7em;
        }
    }

    &-mobile {
        border-radius: 50%;
        position: fixed;
        bottom: 100px;
        right: 20px;
        width: 50px;
        background-color: $primary-main;
        font-size: 2em;
        padding: 7px;
        padding-top: 5px;
        box-shadow: 0px 3px 10px $primary-gris;
        z-index: 5;
    }
}

.button-deedTabs {
    border-radius: none;
    border: none;
    padding: 10px 0px;
    width: 95px;
    color: white;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    font-family: $font;
    cursor: pointer;

    &:hover {
        filter: brightness(105%);
        cursor: pointer;
    }

    &:active {
        filter: brightness(90%);
        cursor: pointer;
    }
}

.button__filters-collapse {
    width: 50px;
    height: 40px;
    //display: inline-flex;
    //align-items: center;
    //justify-content: right;
    //align-content: flex-end;
    float: right;
    font-family: $font;
    cursor: pointer;

    &-icon {
        color: $primary-main;
        font-size: 20px;
    }

    &-caret {
        transition: all 0.2s;
        color: $font-color-medium;
        font-size: 14px;

        &.caretUp {
            transform: rotate(180deg);
        }
    }

    &:hover {
        cursor: pointer;

        .button__filters-collapse-caret {
            padding-top: 5px;
        }
    }
}

.dialogBtn {
    &-delete {
        background-color: $primary-main;
        color: white;

        &:hover {
            background-color: white;
            color: $primary-main;
            border: 1px solid $primary-main;
        }
    }

    &-cancel {
        background-color: $primary-gris;
        color: white;

        &:hover {
            background-color: white;
            color: $primary-gris;
            border: 1px solid $primary-gris;
            text-decoration: none;
        }
    }
}

.buttonGroup {
    letter-spacing: 0.5px;
    background-color: transparent;

    border: none;
    border-radius: 40px;
    cursor: pointer;
    color: #8d8d8d;
    width: 50%;
    height: 100%;
    text-transform: uppercase;
    &.active {
        background-color: #ebb700;
        border-radius: 40px;
        color: white;
    }
}

.custom-toggle-buttons {
    background-color: #fee076;
    border-radius: 40px;
    width: 180px;
    height: 32px;
    text-align: center;
    transition: all 0.4s;
    font-size: 14px;
    font-family: $font;
}